<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="800">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Details for Email ID: {{modalInfo.loginEmailID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{props.item.MerchantRewardTrxRef}}</td>
                            <td>{{props.item.PaymentProvider3rdPartyRefNo}}</td>
                            <td>{{props.item.PaymentMethod}}</td>
                            <td>{{props.item.GIROreference}}</td>
                            <td>{{props.item.GIROtransactiondatetime}}</td>
                            <td>{{props.item.AmountGrandTotal}}</td>
                        </tr>
                    </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: 'Ref. No.', value: 'MerchantRewardTrxRef', width: '3%' },
                { text: 'Transaction ID', value: 'PaymentProvider3rdPartyRefNo', width: '3%' },
                { text: 'Payment Method', value: 'PaymentMethod', width: '3%' },
                { text: 'Giro Reference', value: 'GIROreference', width: '3%' },
                { text: 'Giro Transaction Date', value: 'GIROtransactiondatetime', width: '3%' },
                 { text: 'Amount (RM)', value: 'AmountGrandTotal', width: '3%' },
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.items = [];
            this.items.push({
                MerchantRewardTrxRef: this.modalInfo.MerchantRewardTrxRef,
                PaymentProvider3rdPartyRefNo: this.modalInfo.PaymentProvider3rdPartyRefNo,
                PaymentMethod: this.modalInfo.PaymentMethod,
                GIROreference: this.modalInfo.GIROreference,
                GIROtransactiondatetime: this.modalInfo.GIROtransactiondatetime,
                AmountGrandTotal: this.modalInfo.AmountGrandTotal
            });
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>