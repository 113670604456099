<template>
    <v-dialog
      v-model="dialogLocal"
      width="1000"
    >
      <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            View Invoice
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>

        <v-card-text>
            <v-row style="justify-content: center">
                <v-col>
                    <v-btn v-if="!errText" small color="primary" tile @click="onClick()"><v-icon left small>fa fa-download</v-icon> Download</v-btn>
                </v-col>
                <v-col cols="12">
                    <pdf
                        v-if="!errText"
                        :src="pdfurl"
                        @error="pdferror"
                        style="width: 100%"
                    ></pdf>
                    <span>{{errText}}</span>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import pdf from 'vue-pdf';
import axios from 'axios';

  export default {
    props: ['dialog', 'customText', 'modalInfo', 'pdfurl'],

    components: { pdf },

    data() {
        return {
            dialogLocal: false,
            errText: '',
            items: null,
        }
    },

    

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false)
            this.$emit('closed', false);
        },

        async handleValue(){
            this.items = this.customText;
            this.errText = '';
        },

        pdferror(){
            this.errText = `MissingPDFException: Missing PDF "${this.pdfurl}"`;
        },

        onClick() {
              axios({
                    url: this.pdfurl,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', `Bill_${this.modalInfo.urltext}.pdf`);
                     document.body.appendChild(fileLink);

                     fileLink.click();
                });
          }
    }
  }
</script>